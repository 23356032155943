const data = [
  {
    id: 301,
    text: 'Home',
    url: '/',
  },
  {
    id: 302,
    text: 'About me',
    url: '/#about-me',
  },
  {
    id: 303,
    text: 'Projects',
    url: '/#projects',
  },
  {
    id: 304,
    text: 'Contact',
    url: '/#contact',
  },
]

export default data
