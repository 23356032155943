import React, { useState } from 'react'
import Navbar from './Navbar'

import Sidebar from './Sidebar'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from './ThemeContext'

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }} />
      <ThemeProvider>
        <Navbar toggleSidebar={toggleSidebar} />
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        {children}
      </ThemeProvider>
    </>
  )
}

export default Layout
